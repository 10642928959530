<template>
<div>
	<a-card :bordered="false"  style="margin-bottom:25px;" class="header-solid h-full" :bodyStyle="{paddingTop: 0,}">

		<template #title>
			<a-row type="flex" align="middle">

				<a-col v-if="addFilePermission" :span="24" :md="5" style="width:auto; margin-right: 50px;">
					<div style="display: inline-block; margin-right: 10px;">
						<a-button type="primary"
							@click="showDocumentModal"
						>
							Add Document
						</a-button>
					</div>
					<div v-if="addFolderPermission" style="display: inline-block;">
						<a-button type="secondary"
							@click="showFolderModal"
						>
							Add Folder
						</a-button>
					</div>

				</a-col>

				<a-col :span="24" :md="8" class="add-item-col">
					<!-- Header Search Input -->
					<a-input-search class="header-search"  placeholder="Search for document…" v-model="searchValue" @input="tableSearch">

					</a-input-search>
					<!-- / Header Search Input -->
				</a-col>
			</a-row>
			<MainModal
					v-if="fileModalvisible"
					:title="fileModalTitle"
				 	@handleOk="fileModalHandleOk"
					:handle-cancel="fileModalHandleCancel"
				>
				<MainForm ref="formFields" :formFields="DocumentInputs" :title="fileModalTitle"></MainForm>
			</MainModal>

			<MainModal
					v-if="folderModalvisible"
					:title="folderModalTitle"
					@handleOk="folderModalHandleOk"
					:handle-cancel="folderModalHandleCancel"
				>
				<MainForm ref="folderFormFields" :formFields="FolderInputs" :title="folderModalTitle"></MainForm>
			</MainModal>
		</template>

	</a-card>
	<!-- Documents Table Card -->

	<a-card v-if="getDataPermission" :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<a-table :columns="columns" :data-source="data" :pagination="true">

			<template slot="name"  slot-scope="row">
				<div style="display: flex; align-items: center;">
			    <div :style="getIconStyle(row)">
			      <svg :viewBox="getIconViewBox(row)" xmlns="http://www.w3.org/2000/svg" :fill="getIconFill(row)" :stroke="getIconStroke(row)" :stroke-linecap="getIconStrokeLinecap(row)" :stroke-linejoin="getIconStrokeLinejoin(row)" :stroke-width="getIconStrokeWidth(row)">
			        <path :d="getIconPath(row)" />
			      </svg>
			    </div>
			    <a
			      :style="row.documentType === 'file' ? 'margin-left: 5px;' : 'font-weight: bolder; margin-left: 5px;'"
			      :href="row.location"
			      v-text="row.doc_name"
			      @click.prevent="handleRowClick(row)"
			    />
			  </div>

			</template>

			<template v-if="deletePermission || row.documentType === 'file'" slot="actionsBtn" slot-scope="row">
				<div class="icon-container" style="display: flex; justify-content: flex-end;">
					<a href="javascript:;" v-if="row.documentType === 'file'" v-on:click="downloadItem(row)" style="margin-right: 10px;">
						<v-icon size="25">save</v-icon>
					</a>
					<a href="javascript:;" v-if="deletePermission" v-on:click="DeleteRow(row)">
						<v-icon size="25">delete</v-icon>
					</a>
				</div>
			</template>

		</a-table>
	</a-card>
	<!-- / Documents Table Card -->
</div>

</template>

<script>
import MainModal from '../Modal/MainModal.vue';
import MainForm from '../Forms/MainForm.vue';
import { mapActions } from 'vuex'
import Axios from 'axios';
import firebase from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll, uploadBytes, deleteObject, getMetadata   } from "firebase/storage";
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

Axios.defaults.withCredentials = false;

	export default ({
		components: {
		  MainModal, MainForm
		},
		props: {
			// data: {
			// 	type: Array,
			// 	default: () => [],
			// },
			columns: {
				type: Array,
				default: () => [],
			},
		},
		async mounted() {
			this.initAllowedActions()
			if(!this.getDataPermission) {
				return [];
			}
			this.storage = getStorage();
			this.rootPath = this.$store.getters.user.building_id + '/documents' + '/' + this.$route.meta.permissionName;
			this.currentFolderId = this.rootPath;
			const storageRef = ref(this.storage, this.rootPath);
			this.data = await this.getPageContent(storageRef);
			this.DocumentInputs[0].userToken = this.accessToken;
			this.DocumentInputs[0].BuildingID =  this.BuildingID;
		},

		data() {
			return {
				visibleShowFileModal: false,
				fileModalvisible: false,
				folderModalvisible: false,
				showFileModalTitle: "Document",
				folderModalTitle: "Add Folder",
				fileModalTitle: "Add Document",
				pdfUrl: '',
				data: [],
				originalData:[],
				DocumentInputs: [
					{ 	name: 'location', label: 'Choose File', type:'uploadFile', rules: ['required']},
					// { 	name: 'doc_name', label: 'Document Name', type:'text', rules: ['required']},
					{ name: 'type', label: 'Type', placeholder:'Enter type', type:'selectBox', 'options': [
						{value: 'Insurance', text: 'Insurance'},
						{value: 'Work Request', text: 'Work Request'},
						{value: 'Taxes', text: 'Taxes'},
						{value: 'Assessment', text: 'Assessment'},
						{value: 'Bylaws', text: 'Bylaws'},
						{value: 'Financials', text: 'Financials'},
						{value: 'Board Minutes', text: 'Board Minutes'},
						{value: 'Offering Plan', text: 'Offering Plan'},
						{value: 'Other', text: 'Other'}],
						rules: ['required']
					},
					{ name: 'details', label: 'Details', placeholder:'Enter Details', type:'text', rules: ['']}
				],
				FolderInputs: [
					{ 	name: 'folderName', label: 'Folder Name', type:'text', rules: ['required']},
				],
				formState: {'name':''},
				searchValue: '',
				error: null,
				currentFolderId: null,
				rootPath: '',
				storage: null,
				fileName: null,
				getDataPermission: false,
				addFilePermission: false,
				addFolderPermission: false,
				deletePermission: false,
				errorMessage: '',

			}
		},
		methods: {

			initAllowedActions() {
					const allowedRoleForRole = this.routesByRole[this.user.role];
					console.log("allowedRoleForRole", allowedRoleForRole);
					if(allowedRoleForRole.documents.length === 1 && allowedRoleForRole.documents[0] === '*'){
						this.getDataPermission = true;
						this.addFilePermission = true;
						this.addFolderPermission = true;
						this.deletePermission = true;
					} else {
						allowedRoleForRole.documents.forEach((action) => {
						if (action === 'getDocuments') {
							this.getDataPermission = true;
						} else if (action === 'deleteDocuments') {
							this.deletePermission === true;
						} else if (action === 'addFile') {
							this.addFilePermission === true;
						} else if (action === 'addFolder') {
							this.addFolderPermission === true;
						}
					})
				};
			},

			async getPageContent(storageRef) {
				let mainData = [];
				if(this.currentFolderId !== this.rootPath) {
					let r = (Math.random() + 1).toString(36).substring(7);
					mainData = [{key: r ,doc_name: 'Go to Parent', documentType: 'parent'}]
				}

				const folderSnapshot = await listAll(storageRef);

				let folders = folderSnapshot.prefixes.map((folderRef) => ({
					key: folderRef.fullPath,
					name: folderRef.name,
					doc_name: folderRef.name,
					type: '',
					details: '',
					location: '',
					documentType: 'folder'
				}))

				mainData = mainData.concat(folders);
				let files = folderSnapshot.items.filter((file) => file.name !== 'internal');

				files = await Promise.all(files.map(async (itemRef) => {
					let storageRef = ref(this.storage, this.currentFolderId + '/' + itemRef.name);
					let metadata = await getMetadata(storageRef);
					let url = await getDownloadURL(storageRef);
					return {
						key: itemRef.fullPath,
						doc_name: itemRef.name,
						name: itemRef.name,
						details: metadata.customMetadata ? metadata.customMetadata.details : '',
						location: url,
						created_timestamp: this.UIDate(metadata.timeCreated),
						type: metadata.customMetadata ? metadata.customMetadata.documentType : '',
						documentType: 'file'
					};
				}));
				this.originalData = this.sortTableData(mainData.concat(files));

				return this.originalData;
			},


			async handleRowClick(row) {
				if(row.documentType === 'parent') {
					this.data = await this.goToParentFolder();
				} else if (row.documentType === 'folder') {
					if(!row.key.includes(this.rootPath)) {
						return false;
					}
					this.currentFolderId = row.key;
					const storageRef = ref(this.storage, row.key);
					this.data = await this.getPageContent(storageRef);
				} else if (row.documentType === 'file') {
					return await this.downloadItem(row);
				}
			},

			async goToParentFolder() {
				const parts = this.currentFolderId.split('/');
				parts.pop(); // remove the current folder's name
				if(!this.rootPath || !parts.join('/').includes(this.rootPath)) {
					return this.data;
				}
				this.currentFolderId = parts.join('/');
				const storageRef = ref(this.storage, this.currentFolderId);
				return await this.getPageContent(storageRef);
			},

			downloadItem(row) {
				Axios.get(row.location, { responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data])
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = row.name
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(console.error)
			},

			async DeleteRow(row) {
				if(row.documentType === 'folder') {
					const storageRef = ref(this.storage, this.currentFolderId + '/' + row.name);
					const folderSnapshot = await listAll(storageRef);
					let noFiles = folderSnapshot.items < 2 || folderSnapshot.items[0].name === 'internal'
					if(folderSnapshot.prefixes.length === 0 && noFiles) {
						if(confirm(`Do you really want to delete folder ${row.name}?`)){
							const pathToDelete = this.currentFolderId + '/' + row.name + '/internal';
							this.deleteObject(pathToDelete);
						}
					} else {
						alert('Folder is not empty');
					}
					return;
				}

				if(confirm(`Do you really want to delete file ${row.name}?`)){
					try {
							const pathToDelete = this.currentFolderId + '/' + row.name;
							// Delete the file
							this.deleteObject(pathToDelete)
						} catch (e) {
							console.log('DeleteRow error', e)
						}
				}
			},


			deleteObject(pathToDelete) {
				// Create a reference to the file to delete
				const fileRef = ref(this.storage, pathToDelete);
				// Delete the file
				deleteObject(fileRef).then(async () => {
					const storageRef = ref(this.storage, this.currentFolderId);
					this.data = await this.getPageContent(storageRef);
				}).catch((error) => {
					console.log("Uh-oh, an error occurred! " + error);
				});
			},

			sortTableData(tableData) {
				tableData.sort((d1, d2) => {
						if(d1.documentType === 'parent' || d2.documentType === 'parent') {
							return d1.documentType === 'parent' ? -1 : 1;
						}
					  else if (d1.documentType === 'folder' && d2.documentType === 'folder') {
					    // Sort objects with type 'folder' by the specified format '03-23-2023 00:47:13'
					    return this.compareDates(d1.doc_name, d2.doc_name);
					  } else if (d1.documentType === 'folder') {
					    // 'folder' type objects should come first
					    return -1;
					  } else if (d2.documentType === 'folder') {
					    // 'folder' type objects should come first
					    return 1;
					  } else {
					    // Sort other objects with type 'file' by the default format 'YYYY-DD-MM H:M:18'
					    return this.compareDates(d1.created_timestamp, d2.created_timestamp);
					  }
					});
					return tableData;
			},

			compareDates(dateStringA, dateStringB) {
				const dateA = new Date(dateStringA);
			  const dateB = new Date(dateStringB);
			  return dateB - dateA;
			},

			UIDate(dateInput) {
				const date = new Date(dateInput);
				const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);  // Months are zero-indexed, so we add 1
				const year = date.getFullYear();
				const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
				const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
				const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
				const seconds = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds(); // Months are zero-indexed, so we add 1

				return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
			},

			showDocumentModal(row) {
				this.fileModalvisible = true
		  },
			showFileModal(row) {
				this.pdfUrl = row.location
				this.visibleShowFileModal = true
			},
			showFileModalHandleCancel() {
				this.visibleShowFileModal = false
			},

			showFolderModal() {
				this.folderModalvisible = true
			},
			folderModalHandleCancel() {
				this.folderModalvisible = false
			},

			fileModalHandleCancel() {
				this.fileModalvisible = false
			},

			async fileModalHandleOk(handleOnFinish) {
				try {
					let isValid = this.$refs.formFields.validate()
					if(!isValid){
						return;
					}
					const success =  await this.UploadedFile();
					if(success) {
						if(!this.rootPath || !this.currentFolderId.includes(this.rootPath)) {
							return;
						}
						const storageRef = ref(this.storage, this.currentFolderId);
						this.data = await  this.getPageContent(storageRef);
						this.$refs.formFields.onFinish(true);
						this.fileModalvisible = false;
					} else {
						this.$refs.formFields.onFinish(false,this.errorMessage);
					}
				} catch (e) {
					console.log('fileModalHandleOk error', e)
					this.$refs.formFields.onFinish(false ,this.errorMessage);
				} finally {
					handleOnFinish()
				}
		  },


			async folderModalHandleOk(handleOnFinish) {
				try {
					let isValid = this.$refs.folderFormFields.validate()
					if(!isValid){
						return;
					}

					const payload = this.$refs.folderFormFields.formData;

					const newFolderId = this.currentFolderId + '/'  + payload.folderName + '/'  + 'internal';
					const storageRef = ref(this.storage, newFolderId);


					uploadBytes(storageRef, new Blob(['internal'])).then(async (snapshot) => {
						if(!this.rootPath || !this.currentFolderId.includes(this.rootPath)) {
							return;
						}

						const storageRef = ref(this.storage, this.currentFolderId);
						this.data = await this.getPageContent(storageRef);
						this.$refs.folderFormFields.onFinish(true);
						this.folderModalvisible = false;
						handleOnFinish()
					}).catch((e) => {
			      console.log(e.message);
						this.$refs.folderFormFields.onFinish(false,this.errorMessage);
				  });
		  } catch (e) {
				console.log('folderModalHandleOk error', e)
				this.$refs.folderFormFields.onFinish(false,this.errorMessage);
				handleOnFinish()
			}

		},

		async UploadedFile() {
			const storage = getStorage();
			const payload = this.$refs.formFields.formData;
			const fileOptions = this.$refs.formFields.fileOptions;
			const metadata = {
				contentType: fileOptions.file.type,
				customMetadata : {
				documentType: payload.type,
				details: payload.details
				}
			};

			if(!this.rootPath || !this.currentFolderId.includes(this.rootPath)) {
				return false;
			}

			let fileName = fileOptions.file.name;
			const storageRef = ref(storage, this.currentFolderId);
			const listResult = await listAll(storageRef);

			//find if there is a file with the same name, 
			const fileExists = listResult.items.find((item) => {
				return item.name === fileName;
			});
			// if there is, show a warning and ask user to rename it and upload again
			if(fileExists) {
				console.log('fileExists', fileExists)
				this.errorMessage = 'File with the same name already exists. Please rename the file and upload again.'

				return false;
			}


			const location = this.currentFolderId + '/' + fileName;
			const newStorageRef = ref(storage, location);
			const uploadTask = uploadBytesResumable(newStorageRef, fileOptions.file, metadata);

			  return new Promise((resolve, reject) => {
			    uploadTask.on('state_changed',
			      (snapshot) => {
			        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			        console.log('Upload is ' + progress + '% done');
			        switch (snapshot.state) {
			          case 'paused':
			            console.log('Upload is paused');
			            break;
			          case 'running':
			            console.log('Upload is running');
			            break;
			        }
			      },
			      (error) => {
			        console.log("error", error);
			        switch (error.code) {
			          case 'storage/unauthorized':
			            reject(false);
			            break;
			          case 'storage/canceled':
			            reject(false);
			            break;
			          case 'storage/unknown':
			            reject(false);
			            break;
			          default:
			            reject(error);
			        }
			      },
			      () => {
			        fileOptions.onSuccess(uploadTask.snapshot, fileOptions.file);
			        resolve(true);
			      }
			    );
			  });
			},

			tableSearch() {
				let debouncedSearch = debounce(() => {
					this.filterDocumentsData(this.searchValue)
				}, 500)
				debouncedSearch()
			},

			filterDocumentsData(search) {
	      if (search === '') {
						this.data = this.originalData;
	          return;
	      } else {
	        this.data =  this.data.filter(row => {
	          // Check if any property of the row contains the search value
	          return Object.values(row).some(value => {
	                return String(value).toLowerCase().includes(search.toLowerCase())
	          })
	        })
	      }
			},

		  ...mapActions('documents', ['updateDocument', 'deleteDocument'])
		},
		computed: {
			...mapState({
				user: state => state.auth.user,
				routesByRole: state => state.auth.routesByRole,
			}),
			getIconViewBox() {
	      return function (row) {
	        if (row.documentType === 'folder') {
	          return '0 0 24 24';
	        } else if (row.documentType === 'file') {
	          return '0 0 64 64';
	        } else if (row.documentType === 'parent') {
	          return '0 0 512.001 512.001';
	        }
	        return '';
	      };
    },
    getIconFill() {
      return function (row) {
        return row.documentType === 'folder' ? 'none' : '#000000';
      };
    },
    getIconStroke() {
      return function (row) {
        return row.documentType === 'folder' ? '#000000' : '';
      };
    },
    getIconStrokeLinecap() {
      return function (row) {
        return row.documentType === 'folder' ? 'round' : '';
      };
    },
    getIconStrokeLinejoin() {
      return function (row) {
        return row.documentType === 'folder' ? 'round' : '';
      };
    },
    getIconStrokeWidth() {
      return function (row) {
        return row.documentType === 'folder' ? '2' : '';
      };
    },
    getIconPath() {
      return function (row) {
        if (row.documentType === 'folder') {
          return 'M2,18.8V5.3A2.3,2.3,0,0,1,4.3,3H9.6a1.1,1.1,0,0,1,.8.4l2.8,3.2a1.1,1.1,0,0,0,.8.4h5.6A2.2,2.2,0,0,1,22,9.2v9.7A2.2,2.2,0,0,1,19.8,21H4.2A2.2,2.2,0,0,1,2,18.8Z';
        } else if (row.documentType === 'file') {
					return 'M56,0H8C5.789,0,4,1.789,4,4v56c0,2.211,1.789,4,4,4h48c2.211,0,4-1.789,4-4V4C60,1.789,58.211,0,56,0z M58,60c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2V4c0-1.104,0.896-2,2-2h48c1.104,0,2,0.896,2,2V60z M49,25H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,25,49,25z M49,19H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,19,49,19z M49,37H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,37,49,37z M49,43H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,43,49,43z M49,49H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,49,49,49z M49,31H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,31,49,31z M15,15h16c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,15,15,15z';
        } else if (row.documentType === 'parent') {
          return 'M384.834,180.699c-0.698,0-348.733,0-348.733,0l73.326-82.187c4.755-5.33,4.289-13.505-1.041-18.26 c-5.328-4.754-13.505-4.29-18.26,1.041l-82.582,92.56c-10.059,11.278-10.058,28.282,0.001,39.557l82.582,92.561 c2.556,2.865,6.097,4.323,9.654,4.323c3.064,0,6.139-1.083,8.606-3.282c5.33-4.755,5.795-12.93,1.041-18.26l-73.326-82.188 c0,0,348.034,0,348.733,0c55.858,0,101.3,45.444,101.3,101.3s-45.443,101.3-101.3,101.3h-61.58 c-7.143,0-12.933,5.791-12.933,12.933c0,7.142,5.79,12.933,12.933,12.933h61.58c70.12,0,127.166-57.046,127.166-127.166 C512,237.745,454.954,180.699,384.834,180.699z';
        }
        return '';
      };
    },
		getIconStyle() {
      return function (row) {
        return {
          width: row.documentType === 'file' ? '15px' : '16px', // Adjust the width as needed
          height: row.documentType === 'file' ? '15px' : '16px', // Adjust the height as needed
        };
      };
    },
		},

	})

</script>

<style scoped>
.icon-container {
  display: flex;
  justify-content: space-between;
}


</style>